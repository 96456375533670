.main_css_us {
    border-style: solid;
    border-color: #cccccc;
    border-width: 1px;
}

.main_css_jp {
    display: flex;
    padding: 9px 0px;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 0 0;
    background: var(--gray-100, #f1f3f6);

    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border: none !important;
    border-radius: 0px !important;

    div > span {
        color: var(--text01, #232323) !important;
        font-family: "Noto Sans JP";
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 15px; /* 107.143% */
    }
}

.main_css_jp.rs-picker-focused {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.rs-picker-toggle.rs-btn.rs-btn-default {
    padding: 0px !important;
}
