.form-content-flexgrid {
    max-width: 516px;
    padding-inline: 8px;

    @media screen and (max-width: 768px) {
        max-width: 540px;
        padding-inline: 20px;
    }
}

.message-alert {
    margin-bottom: 16px;
    background-color: #FFFFFF;
    border: 1px solid #209CFF;
    color: #1764E9;
    > .rs-message-container {
        > .rs-message-content {
            color: #1764E9;
        }
    }
}