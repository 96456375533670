.top-banner-pc {
    width: 100%;
    height: auto;
    display: block !important;

    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

.top-banner-sp {
    width: 100%;
    height: auto;
    display: none !important;


    @media screen and (max-width: 768px) {
        display: block !important;
    }
}

.campaign-overview {
    background: #E9F5FF;
}

.campaign-content-flexgrid {
    max-width: 516px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-inline: 8px;
    margin-bottom: 0px;
    direction: flex;
    justify-content: left;

    @media screen and (max-width: 768px) {
        max-width: 540px;
        padding-inline: 20px;
    }
}

.campaign-list-title {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;

    color: #00548E;

    padding-top: 10px;
    padding-bottom: 8px;

    @media screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 23px;
    }
}

.campaign-list-emphasize {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;

    color: #494949;

    @media screen and (max-width: 768px) {
        font-size: 15px;
        line-height: 22px;
    }
}

.campaign-list-normal {font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;

    color: #494949;

    @media screen and (max-width: 768px) {
        font-size: 15px;
        line-height: 22px;
    }
}

.form-content-flexgrid {
    max-width: 516px;
    padding-inline: 8px;

    @media screen and (max-width: 768px) {
        max-width: 540px;
        padding-inline: 20px;
    }

    @media screen and (min-width: 768px) {
        width: 516px;
    }
}

.input-name-flexgrid-left {
    max-width: 240px;

    @media screen and (max-width: 768px) {
        padding-right: 5px;
    }
}
.input-name-flexgrid-right {
    max-width: 240px;

    @media screen and (max-width: 768px) {
        padding-left: 5px;
    }
}

.message-alert {
    margin-bottom: 16px;
    background-color: #FFFFFF;
    border: 1px solid #209CFF;
    color: #1764E9;
    > .rs-message-container {
        > .rs-message-content {
            color: #1764E9;
        }
    }
}