.main_css_us {
    border-style: solid;
    border-color: #CCCCCC;
    border-width: 1px;
}

.main_css_jp {
    border-style: none;
    border-width: 1px;
    background-color: #F1F3F6;
    border-radius: 3px;
    padding: 16px 18px;

    font-size: 18px;

    color: #00548E;

    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.main_css_jp::placeholder {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #00548E99;
}