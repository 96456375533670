.main_css_us {
    color: #ffffff;
    height: 36px;
    background: #1764e9;
    border: 1px solid #1764e9;
    gap: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 4px;
    min-width: 150px;
}

.main_css_jp {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 320px;
    height: 55px;
    padding: 15px;
    padding-left: 10px;
    flex: 1 0 0;
    border-radius: 8px;
    background: var(--medal, #00548e);
    color: var(--white, #fff);
    font-feature-settings: "pwid" on;
    font-family: "Noto Sans JP";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    letter-spacing: 0.72px;

    &:hover {
        color: var(--white, #fff);
        background: var(--sky-800, #0089d0);
    }

    @media screen and (max-width: 768px) {
        font-size: 16px;
        padding: 12px 20px;
        gap: 12px;
        border: 2px solid #209cff;
        height: 44px;
    }
}
