.main_css_us {
    border-style: solid;
    border-color: #cccccc;
    border-width: 1px;
}

.main_css_jp {
    display: flex;
    justify-content: center;
    align-items: center;
    .text {
        color: var(--text01, #232323);

        /* En Small */
        font-family: "Poppins", "Poppins-fallback", Arial, sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 107.692% */
    }

    div {
        padding-left: 28px !important;
        padding-top: 0px !important;
        padding-bottom: 8px !important;
        min-height: 0px !important;

        label > span:nth-child(1) {
            left: 0px !important;
            top: 0px !important;
        }
    }

    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
