.main_css_US {
    color: #2C2D40,
}

.main_css_JP {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    position: absolute;
    width: 100%;

    /* Logo/001 */

    background: #209CFF;
    color: #FFFFFF
}

.a_css_US {
    color: #FFFFFF
}

.a_css_JP {
    color: #FFFFFF
}