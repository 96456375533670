.main_css_JP {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.main_css_svg {
    width: 18px;
    height: 18px;
    fill: var(--logo-blue, #209cff);
}

.main_css_text {
    color: var(--text01, #232323);
    font-feature-settings: "pwid" on;

    /* JP/P */
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.56px;
}
