.main_css_us {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 51px;
    /* identical to box height */

    letter-spacing: 0.0289em;

    color: #494949;

}

.main_css_jp {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 51px;
    /* identical to box height */

    letter-spacing: 0.0289em;

    color: #494949;

    @media screen and (max-width: 768px) {
        font-size: 26px;
        line-height: 38px;
        letter-spacing: 0.0289em;
    }
}