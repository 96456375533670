.main_css_us {
    color: #FFFFFF;
    height: 36px;
    background: #1764E9;
    border: 1px solid #1764E9;
    gap: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 4px;
    min-width: 150px
}

.main_css_jp {
    color: #209CFF;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #209CFF;
    height: 67px;

    gap: 10px;
    justify-content: center;
    align-items: center;
    display: flex;

    padding: 16px 40px;
    border-radius: 1px;

    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    /* identical to box height */

    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 16px;
        padding: 12px 20px;
        gap: 12px;
        border: 2px solid #209CFF;
        height: 44px;
    }
}