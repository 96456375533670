.required-label {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Primary-MainColor-RevCommBlue, #209cff);
}

.required-label-text {
    color: var(--white, #fff);
    font-family: "Noto Sans JP";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 11px; /* 100% */
    letter-spacing: 1.1px;
}
