.main_css_US {
    color: #2c2d40;
}

.main_css_JP {
    padding: 40px 30px;
    flex-direction: column;
    gap: 30px;
    align-self: stretch;
    background: var(--Logo-001, #209cff);

    @media screen and (max-width: 1090px) {
        // width: 375px;
        display: flex;
        padding: 30px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;
    }
}

.top {
    display: flex;
    align-items: flex-start;
}

.bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;

    @media screen and (max-width: 1090px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
    }
}

.bottom-item-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.bottom-copy-link {
    color: #fff;
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}

.bottom-item-group-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;

    @media screen and (max-width: 1090px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.bottom-item-group-logo-section1 {
    display: flex;
    align-items: flex-start;
    gap: 15px;
}
.bottom-item-group-text {
    color: #fff;
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}

.hidden-md {
    @media screen and (max-width: 1090px) {
        display: none;
    }
}

.mm-logo-img {
    width: 300px;
}

.a_css_US {
    color: #ffffff;
}

.a_css_JP {
    color: #ffffff;
}
