.main_css_US {
    color: #494949;
    padding: 9px 14px;
    height: 50px;
    background: #FFFFFF;
    border-bottom: solid 1px #DADEE5;
    overflow: hidden;
    gap: 10px;
    align-items: center;
    display: flex;
}

.main_css_JP {
    color: #494949;
    padding: 9px 14px;
    height: 50px;
    background: #FFFFFF;
    border-bottom: solid 3px #494949;
    overflow: hidden;
    gap: 10px;
    align-items: center;
    display: flex;
}

.logo_css_US {
    padding-left: 150px;
}

.logo_css_JP {
    padding-left: 150px;
}

@media only screen and (max-width: 480px) {
    .logo_css_US {
        padding-left: 0;
    }

    .logo_css_JP {
        padding-left: 0;
    }
}