.main_css_us {
    color: #2c2d40;
}

.main_css_jp {
    display: flex !important;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.text {
    color: var(--text01, #232323);
    font-feature-settings: "pwid" on;

    /* JP/P */
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 21px */
    letter-spacing: 0.56px;
}
