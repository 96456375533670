body {
  margin: 0;
  font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F9FA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.StripeElement {
  padding: 16px 18px;
  width: 100%;
  background-color: #F1F3F6;
  border: none;
  border-radius: 4px;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.custom-input-number input {
  text-align: center;
}

.custom-input-number .rs-input-number-btn-group-vertical {
  display: none;
}

.custom-input-number.rs-input-group-disabled {
  background-color: #f7f7fa;
}

.rs-form-vertical .rs-form-group .rs-input-group {
  width: 100%;
}


/* 本体部分 */
.step {
  width: 30px;
  height: 30px;
  background-color: #C5CBD4;
  display: inline-block;
  border: 1px solid;
  border-color: #C5CBD4;
  border-radius: 50%;

  /* font-weight: 600; */
  text-align: center;
  line-height: 24px;
}
.step:first-child {
  line-height: 30px;
}
.step:first-child >  .description{
  line-height: 20px;
}
.step:nth-child(n+2) {
  margin: 0 0 0 100px;
}
.step:nth-child(n+2):before {
  width: 50px;
  height: 2px;
  display: block;
  background-color: #DDD;
  transform: translate(-77px, 13px);
  content: '';
}
.step:after {
  width: 150px;
  display: block;
  transform: translate(-55px, 3px);

  color: #32325d;
  content: attr(data-desc);
  font-weight: 400;
  font-size: 13px;
}
.step:first-child:after {
  transform: translate(-55px, -1px);
}
.step.active {
  background-color: #6BA0FC;
  border-color: #6BA0FC;
  color: #FFFFFF;
}
.step.active > .description{
  color: #2C2D40;
}

/* .step.active:before {

} */
/* .step.active:after {
  color: hsl(206,72%,68%);
} */
.step.done {
  color: #FFF;
  background-color: #6BA0FC;
  border-color: #6BA0FC;
}
/* .step.done:before {
  background-color: hsl(120,42%,54%);
} */

.step > .title{
  color: #FFF;
}
.step > .description{
  color: #A0A9B5;
}

.rs-panel-header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  background-color: #FFFFFF;
}

.rs-panel {
  background-color: #FFFFFF;
}

.thank-you-title {
  color: #2C2D40;
  font-weight: 700;
  font-size: 20px;
  padding: 24px;
}

.thank-you-message {
  color: #2C2D40;
  font-size: 14px;
  padding: 0px 80px 53px 80px;
  max-width: 550px;
}

.rs-picker-input .rs-picker-toggle {
  position: absolute !important;
  width: 95%;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #00548E99;
  background-color: #F1F3F6 !important;
}

span.rs-picker-toggle-value {
  color: #00548E !important;
}


.rs-picker-toggle-active, .rs-picker.rs-picker-focused {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;

}

@media only screen and (max-width: 480px) {
  body {
    background-color: #FFFFFF;
  }
  .step:nth-child(n+2) {
    margin: 0 0 0 50px;
  }

  .step:nth-child(n+2):before {
    width: 20px;
    height: 2px;
    display: block;
    background-color: #DDD;
    transform: translate(-35px, 13px);
    content: '';
  }

  .thank-you-title {
    color: #2C2D40;
    font-weight: 700;
    font-size: 20px;
    padding: 24px;
  }

  .thank-you-message {
    color: #2C2D40;
    font-size: 14px;
    padding: 0px 20px 53px 20px;
    max-width: 550px;
  }

  .rs-panel-shaded{
    box-shadow:none !important;
  }
}