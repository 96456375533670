.main_css_us {
    color: #2C2D40,
}

.main_css_jp {
    font-family: 'Noto Sans JP';
    background-color: #F1F3F6;

    font-size: 18px;

    color: #00548E;
    width: 100%;

    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    width: 100%;
    border: none;
    background-color: #F1F3F6;
}