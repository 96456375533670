.main_css_us {
    border-style: solid;
    border-color: #CCCCCC;
    border-width: 1px;
}

.main_css_jp {
    border-style: none;
    border-width: 1px;
    border-radius: 3px;
    padding: 10px 16px;

    font-size: 16px;

    color: #00548E;

    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}