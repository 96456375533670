.main_css_us {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 36px;
    letter-spacing: 0.1em;

    color: #209CFF;

}

.main_css_jp {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 36px;
    letter-spacing: 0.1em;

    color: #209CFF;

    @media screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 23px;
    }
}