.body {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 1090px) {
        display: flex;
        padding: 20px 16px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
    }
}

.form-container {
    display: flex;
    overflow-y: hidden;
    width: 459px;
    padding: 40px 0px 80px 0px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;

    @media screen and (max-width: 1090px) {
        order: 2;
        width: 100%; // Line+1pxのため、横幅全体は375px
    }
}

.form-frame {
    display: flex;
    width: 320px;
    flex-direction: column;
    align-items: center;
    gap: 18px !important;
    flex-shrink: 0;
}

.form-frame-title {
    color: var(--text01, #232323);
    font-feature-settings: "pwid" on;

    /* SP H2 */
    font-family: "Noto Sans JP";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
}

.form-input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px !important;
    align-self: stretch;
    margin-bottom: 0px !important;
}

.form-input-group-additional-text {
    color: var(--text01, #232323);
    font-feature-settings: "pwid" on;

    /* JP Small */
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}

.form-input-group-frame {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.form-input-group-frame-inner {
    display: flex;
    align-items: flex-start;
    gap: 16px;
}

.form-input-group-frame-inner2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.form-input-group-checkbox {
    display: flex;
    align-self: stretch;
    flex-direction: column;
}

.form-input-group-checkbox-item {
    display: flex;
    align-items: center;
}

.form-input-group-picker-frame {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.input-name-flexgrid-right {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.form-label-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.form-label-help-text {
    color: var(--text01, #232323);
    font-feature-settings: "pwid" on;

    /* JP Small */
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}

.accept-checkbox {
    div:nth-child(1) {
        padding-top: 5px;
    }
    div > div {
        padding-left: 28px !important;
        padding-bottom: 0px !important;
        label > span {
            left: 0px !important;
        }
    }
}

.accept-checkbox-frame {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.accept-checkbox-text {
    color: var(--logo-gray, #494949);
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.accept-checkbox-text-link {
    color: var(--logo-blue, #209cff);
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
    text-decoration-line: underline;
}

.line {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.line2 {
    width: 1px;
    height: 100%;
    background: #d2d8e2;
}

.section-right {
    display: flex;
    position: sticky;
    top: 0px;
    width: 100%;
    // min-width: 1024px;
    padding: 40px 140px;
    flex-direction: column;
    align-items: center;
    flex: 1 0;
    flex-shrink: 1;

    @media screen and (max-width: 1440px) {
        display: flex;
        padding: 40px 80px;
        flex-direction: column;
        align-items: center;
        flex: 1 0 0;
    }

    @media screen and (max-width: 1090px) {
        order: 1;
        position: static;
        width: 100%;
        padding: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
        min-width: 0px;
    }
}

.section-right-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    max-width: 1181px;
}

.section-right-frame-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: -15px;
}

.section-right-frame-title-top {
    display: flex;
    height: 51px;
    flex-direction: column;
    align-items: center;
    gap: -5px;
}

.section-right-frame-title-tips {
    display: flex;
    padding: 6px 14px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: var(--CV_color, #ffac14);
}

.section-right-frame-title-tips-text {
    color: var(--white, #fff);
    font-feature-settings: "pwid" on;
    font-family: "Noto Sans JP";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    letter-spacing: 0.64px;
}

.section-right-frame-title-polygon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section-right-frame-title-polygon-svg {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0px;
    fill: var(--CV_color, #ffac14);
}

.section-right-frame-title-under {
    display: flex;
    padding: 7px 10px 12px 10px;
    justify-content: center;
    align-items: center;
    gap: -4px;
}

.section-right-frame-title-under-text1 {
    color: var(--text01, #232323);
    text-align: center;
    font-feature-settings: "pwid" on;
    font-family: "Noto Sans JP";
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 53px; /* 110.417% */
    letter-spacing: 1.92px;
}

.section-right-frame-title-under-text2 {
    color: var(--text01, #232323);
    text-align: center;
    font-feature-settings: "pwid" on;
    font-family: "Noto Sans JP";
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 53px; /* 110.417% */
    letter-spacing: -3.84px;
}

.section-right-frame-img {
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1090px) {
        display: flex;
        // width: 305px;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
}

.img-mv {
    width: 100%;
    height: auto;
    object-fit: contain;
    @media screen and (max-width: 1440px) {
        height: 200px;
    }
}

.section-right-frame-functions {
    display: flex;
    padding: 20px 20px 30px 20px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-self: stretch;
}

.section-right-frame-functions-title {
    color: var(--text01, #232323);
    text-align: center;
    font-feature-settings: "pwid" on;
    font-family: "Noto Sans JP";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: 0.8px;

    @media screen and (max-width: 505px) {
        font-size: 18px;
    }
}

.section-right-frame-functions-frame {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 0px 20px;
    align-self: stretch;
    flex-wrap: wrap;

    @media screen and (max-width: 505px) {
        display: flex;
        width: 305px;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;
        gap: 0px 50px;
        flex-wrap: wrap;
    }
}

.section-right-frame-functions-frame-items {
    display: flex;
    padding: 4px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media screen and (max-width: 505px) {
        &:nth-child(3) {
            flex-direction: row;
            align-items: flex-start;
            gap: 10px;
        }
    }
}

.section-right-frame-details {
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: var(--main-color-8, rgba(32, 156, 255, 0.08));
}

.section-right-frame-details-frame {
    flex: 1 0 0;
}

.section-right-frame-details-text {
    color: var(--text01, #232323);
    font-feature-settings: "pwid" on;
    margin: 0px;

    /* JP Small */
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}

.message-alert {
    width: 100%;
    margin-bottom: 16px;
    background-color: #ffffff;
    border: 1px solid #209cff;
    color: #1764e9;
    > .rs-message-container {
        > .rs-message-content {
            color: #1764e9;
        }
    }
}
