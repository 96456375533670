.container {
    display: flex;
    padding: 60px 0px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    height: 70vh;
    min-height: 560px;

    @media screen and (max-width: 768px) {
        display: flex;
        padding: 20px 16px;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        align-self: stretch;
    }
}

.title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;
    }
}

.inline-block {
    @media screen and (max-width: 768px) {
        display: inline-block;
    }
}

.title-h3 {
    color: var(--text01, #232323);
    font-feature-settings: "pwid" on;

    /* JP H3 */
    font-family: "Noto Sans JP";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    letter-spacing: 0.72px;

    @media screen and (max-width: 768px) {
        color: var(--text01, #232323);
        font-feature-settings: "pwid" on;
        /* SP H3 */
        font-family: "Noto Sans JP";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
    }
}

.title-h2 {
    color: var(--text01, #232323);
    font-feature-settings: "pwid" on;

    /* JP/H2 */
    font-family: "Noto Sans JP";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 48px */
    letter-spacing: 1.28px;

    @media screen and (max-width: 768px) {
        color: var(--text01, #232323);
        font-feature-settings: "pwid" on;

        /* SP H2 */
        font-family: "Noto Sans JP";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 30px */
    }
}

.img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img-content {
    display: flex;
    width: 220px;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}

.trial-check-email-img {
    height: 200px;
}

.sentence-container {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}

.sentence {
    color: var(--text01, #232323);
    text-align: center;
    font-feature-settings: "pwid" on;

    /* JP/P */
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.56px;
}
