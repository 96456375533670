.main_css_JP {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}

.header_logo-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    background: var(--white, #fff);

    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.header_logo-inner-container-nav {
    display: flex;
    width: 1280px;
    height: 70px;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 1280px) {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
    }
}

.header_logo-inner-container-nav-item {
    display: flex;
    width: 310px;
    height: 70px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;

    @media screen and (max-width: 1280px) {
        display: flex;
        height: 70px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
}

.header_logo-inner-container-nav-item-space {
    display: flex;
    width: 1280px;
    height: 70px;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 1280px) {
        display: none;
    }
}

.logo_css_JP {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo_css_US {
    padding-left: 150px;
}

.rectangle {
    height: 4px;
    align-self: stretch;
    background: linear-gradient(180deg, #f0f0f0 0%, rgba(217, 217, 217, 0) 100%);
}

@media only screen and (max-width: 480px) {
    .logo_css_JP {
        padding-left: 0;
    }
}
