.main_css_us {
    color: #2C2D40,
}

.main_css_jp {
    font-family: 'Noto Sans JP';
    border-style: none;
    border-width: 1px;
    background-color: #F1F3F6;
    border-radius: 3px;
    padding: 10px 18px;

    font-size: 18px;

    color: #00548E;
    width: 100%;

    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
.main_css_jp::placeholder {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #00548E99;
}

.main_css_jp > input {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #00548E;
    background-color: #F1F3F6;
}