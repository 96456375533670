.main_css_us {
    border-style: solid;
    border-color: #cccccc;
    border-width: 1px;
}

.main_css_jp {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    display: flex;
    padding: 16px 18px;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    background: var(--gray-100, #f1f3f6);
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-radius: 0px !important;
    border: none !important;
}

.main_css_jp::placeholder {
    color: var(--Place-Holder-Text, rgba(35, 35, 35, 0.4));
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px; /* 107.143% */
}
